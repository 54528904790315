.Button.btn {
  border: 0;
  border-radius: 0.2rem;
  background-color: #FF6200;
  color: #FFF;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.Button.btn:hover {
  cursor: pointer;
}

.Button.btn.catuaba {
  color: #731ab7;
  background: #ffffff;
  border: 0.098rem solid #731ab7;
  box-sizing: border-box;
  border-radius: 0.2rem;
}

.Button.btn.catuaba:hover {
  background: #491173;
  color: #fff;
}

.Button.btn.caipirinha {
  color: #fff;
  background: #FF6200;
  transition: transform .1s;
  box-sizing: border-box;
  border-radius: 0.2rem;
}

.Button.btn.caipirinha:not(.nohover):hover {
  transform: scale(1.1)
}

.Button.btn:disabled {
  background: #bdbdbd;
  color: #000000;
}

.Button.btn-small {
  height: 2.6rem;
  font-size: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.Button.btn-medium {
  padding: 1.5rem 2rem 1.5rem 2rem;
  font-size: 1.6rem;
}

.Button.btn-large {
  width: 11.2rem;
  height: 5.6rem;
  font-size: 2rem;
}

.Button.btn-extra-large {
  padding: 1rem;
  font-size: 1.7rem;
  margin-top: 2rem;
}

.Button.btn-extra-large:hover {
  background: #FF6200;
  color: #FFF;
}

.Button.btn-super-extra-large {
  font-size: 2rem;
  margin-top: 3.4rem;
}

.Button.btn-extra-large-secondary {
  font-size: 2rem;
  background-color: #ffffff;
  color: #63dc3e;
}

.Button.btn-extra-large-white {
  padding: 1rem;
  font-size: 1.7rem;
  margin-top: 2rem;
  background-color: #ffffff;
  color: #000000;
}

.Button.disabled {
  background: #bdbdbd;
  color: #000000;
}

.Button.animation-jelly {
  animation: jelly 0.5s;
}

.Button.btn-custom {
  margin: 0 4.1rem 2rem 4.1rem;
  padding: 1.5rem 2rem 1.5rem 2rem;
  font-size: 2rem;
  margin-top: 1rem;
}

.Button.btn-white {
  background: #ffffff;
  border: 0.1rem solid #242523;
  box-sizing: border-box;
  border-radius: 0.3rem;
}

.Button.btn-white:hover {
  background: #3fab1e;
}

@keyframes jelly {
  0%,
  100% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
