.popup-container {
  position: fixed;
  width: 30rem;
  height: 30rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
}

.popup-h1 {
  font-weight: bold;
  font-size: 22px;
  line-height: 25px;
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #80808065;
  padding: 1rem;
  justify-content: space-between;
}

.popup-p {
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 2rem;
  border-bottom: 0.1rem solid #80808065;
}

.popup-img {
  cursor: pointer;
}

.popup-close {
  display: none;
}

.button-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem 1rem 2rem;
}

.button-popup {
  width: 26rem;
  height: 4rem;
  left: 2.3rem;
  top: 22rem;
  background: #fff;
  border-radius: 2px;
  font-weight: bold;
  font-size: 16px;
  line-height: 113.13%;
  color: #242523;
  cursor: pointer;
  border: 0.1rem solid #000;
}

.button-popup:hover {
  background: '#rgb(255 98 0)';
}

.button-popup-white {
  width: 26rem;
  height: 4rem;
  left: 2.3rem;
  top: 22rem;
  background: #fff;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 113.13%;
  color: #242523;
  cursor: pointer;
  border: 0.1rem solid #000;
  margin-top: 1rem;
}

.button-popup, .button-popup-white {
  transition: all .1s;
}

.button-popup:hover, .button-popup-white:hover {
  background-color: #ddd;
}

.popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.popup-p div.small p {
  font-size: 1.4rem;
}

.p-popup-container {
  display: flex;
  flex-flow: column wrap;
}

.p-popup {
  font-size: 1rem;
  padding-bottom: 0.6rem;
}
