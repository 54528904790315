.loading-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 15rem;
  animation: zoomIn 2s linear infinite;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
