.question-container {
  display: flex;
  flex-flow: column;
  padding: 1rem 2rem 0;
  background: #ffffff;
  margin-top: 0rem;
  max-width: 105rem;
}

.question-main {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.question-checkbox {
  display: flex;
  flex-flow: column wrap;
}

.question-number {
  font-weight: 300;
  font-size: 1.2rem;
}

.question-title {
  font-size: 1.9rem;
  font-weight: 500;
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #242523;
  word-break: break-word;
}

.question-container-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-img {
  padding-top: 3.5rem;
  max-width: 28rem;
}

.question-error {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 1rem;
  color: #fc3333;
}

.action-bar {
  display: flex;
  justify-content: center;
}

.action-bar > * {
  margin-right: 1rem;
}

.action-bar > *:last-child {
  color: #242523;
}

.answers {
  padding-top: 1rem;
  word-break: break-word;
}

.pre {
  white-space: pre-wrap;
}

/**************************** Media Querys ****************************/

@media only screen and (max-width: 720px) {
  .question-container {
    max-width: 37.5rem;
  }
}

@media screen and (max-width: 360px) {
  .question-container {
    max-width: 31.5rem;
  }
}
