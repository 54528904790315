.assessment-list-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  min-height: calc(100vh - 12.1rem);
  min-width: 100%;
  background: #fff;
}

.assessment-list-container .assessment-list-header {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 100%;
  height: 5rem;
  background: rgb(197,106,32);
  background: -moz-linear-gradient(270deg, rgba(197,106,32,1) 0%, rgba(215,200,188,1) 100%);
  background: -webkit-linear-gradient(270deg, rgba(197,106,32,1) 0%, rgba(215,200,188,1) 100%);
  background: linear-gradient(270deg, rgba(197,106,32,1) 0%, rgba(215,200,188,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c56a20",endColorstr="#d7c8bc",GradientType=1);
}

.assessment-list-container .assessment-list-header h1 {
    font-weight: bold;
  font-size: 2.4rem;
  margin-left: 1rem;
}

.assessment-list-container .assessment-list-card-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: center;
  flex-grow: 1;
  padding-top: 2rem;
}

.assessment-list-container .assessment-card {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 19.8rem;
  height: 25rem;
  border: 0.1rem solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  margin: 1rem;
  background-color: #fff;
  transform: translateZ(0px);
  transition-duration: 0.3s;
  transition-property: transform;
  cursor: pointer;
}

.assessment-list-container .assessment-card:hover {
  transform: scale(1.05);
}

.assessment-list-container .assessment-card .content {
  padding: 1rem;
  margin-top: auto;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: space-evenly;
}
.assessment-list-container .assessment-card .content img {
  max-height: 150px;
}
.assessment-list-container .assessment-card .name {
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
}

.assessment-list-container .assessment-card .description {
  font-size: 1.4rem;
  text-align: center;
}
.assessment-list-container .assessment-card .progress {
  display: flex;
  align-items: center;
  background-color: white;
}

.assessment-list-container .assessment-card .progress progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 0.5rem;
  flex-grow: 1;
}
.assessment-list-container .assessment-card .progress .percent {
  margin-left: 1rem;
}

.assessment-list-container .assessment-card .progress progress::-webkit-progress-bar {
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.assessment-list-container .assessment-card .progress progress::-moz-progress-value {
  background: #03045e;
}

.assessment-list-container .assessment-card .progress progress::-webkit-progress-value {
  background: #03045e;
}

.assessment-list-container .assessment-card .link {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  background: #FF6200;
  color: #FFF;
}

.assessment-list-container .assessment-card:hover .link {
  background: #FF6200;
  cursor: pointer;
  text-decoration: underline;
}

.assessment-list-container .assessment-card .link.started {
  background: #fff;
  color: #242523;
  border-top: 0.1rem solid grey;
}

.assessment-list-container .assessment-card:hover .link.started {
  background: #FF6200;
  cursor: pointer;
  color: #fff;
}

.assessment-list-container .assessment-list-subtitle {
  display: flex;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  padding: 1rem 0 1rem 0;
  background: #fff;
}

.assessment-list-container .assessment-list-subtitle h1 {
  font-weight: 600;
  font-size: 1.8rem;
  margin-left: 1rem;
}

.assessment-list-container .assessment-list-cta {
  text-align: center;
  margin: 2.5rem;
}

.assessment-list-container .assessment-list-cta h1 {
  font-weight: 600;
  font-size: 2rem;
  margin: 3rem 0 1rem 0;
}

.assessment-list-container .assessment-list-action-bar div h1 {
  font-weight: 800;
  font-size: 2.8rem;
  text-align: center;
  color: #242523;
}

.assessment-list-container .assessment-list-action-bar div p {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1rem;
}

.assessment-list-container .status-container {
  display: flex;
  flex-flow: row wrap;
}

.assessment-list-container .status-p {
  font-size: 1.4rem;
  text-align: center;
}
