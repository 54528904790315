.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  min-height: 5.1rem;
  background: #242523;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.footer-copy {
  padding-top: 1.1rem;
  font-size: 1.5rem;
  color: #ffffff;
  margin: 0 auto;
}

.footer-icons-container {
  list-style-type: none;
  font-size: 2.6rem;
  margin: 0 auto;
}

.footer-icons {
  margin: 2rem;
  text-decoration: none;
  color: #FF6200;
  width: 15px;
}

@media only screen and (max-width: 720px) {
  .navbar-logo {
    padding-left: 0;
    -webkit-flex-grow: 10;
    flex-grow: 10;
  }

  .footer-copy {
    font-size: 1.6rem;
  }

  .footer-icons-container {
    list-style-type: none;
    font-size: 2.6rem;
    margin: 0 auto;
  }
}

@media screen and (device-aspect-ratio: 40/71) {
  .navbar-logo {
    padding-left: 0;
    -webkit-flex-grow: 10;
    flex-grow: 10;
  }

  .footer-copy {
    font-size: 1.2rem;
  }

  .footer-icons-container {
    list-style-type: none;
    font-size: 2.6rem;
    margin: 0 auto;
  }
}
