.container {
  display: flex;
  flex-flow: column wrap;
  min-height: calc(100vh - 12.1rem);
  min-width: 100%;
}

.h1-title {
  font-size: 3.3rem;
  text-align: center;
  margin-top: 2rem;
}

.h1-title-2 {
  font-size: 3.2rem;
  text-align: center;
}

.p-title {
  font-size: 3.3rem;
  text-align: center;
  margin-top: 2rem;
  color: #242523;
}

.container-choices {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 2rem 0;
  padding: 10rem 10rem;
  background-color: #FF6200;
}

.choices {
  background: #ffffff;
  border: 0.4px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 3.2638px 3.2638px 3.2638px rgba(0, 0, 0, 0.1);
  width: 19.5rem;
  height: 21.5rem;
  text-align: center;
  cursor: pointer;
  margin: 1rem;
  padding-top: 3.5rem;
  transform: translateZ(0px);
  transition-duration: 0.3s;
  transition-property: transform;
}

.inactive {
  background: #c2c2c2;
  border: 0.407975px solid #c2c2c2;
  box-sizing: border-box;
  box-shadow: 3.2638px 3.2638px 3.2638px rgba(0, 0, 0, 0.1);
  width: 19.5rem;
  height: 21.5rem;
  left: 49.8rem;
  top: 27.8rem;
  text-align: center;
  margin: 1rem;
  padding-top: 3.5rem;
  color: #242523;
}

.choices:hover {
  transform: scale(1.05);
}

.choices-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #242523;
  padding-top: 4.5rem;
}

.choices-p {
  font-size: 1.6rem;
  text-align: center;
  color: #242523;
  font-weight: lighter;
  margin-top: 1rem;
}

.register-stack {
  padding: 1rem;
  border-radius: 1rem;
  background-color: #0056b3;
  font-weight: bold;
  cursor: pointer;
  width: 20%;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.register-stack:hover {
  color: #fff;
}

.register-buttons-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
}


/**************************** Media Querys ****************************/

@media only screen and (max-width: 720px) {
  .container-choices {
    margin: 0;
    padding: 0;
  }

  .h1-title {
    font-size: 2rem;
    text-align: center;
    margin-top: 2rem;
  }

  .h1-title-2 {
    font-size: 1.9rem;
    text-align: center;
  }

  .p-title {
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #242523;
  }
  .register-stack {
    width: 100%;
    margin:.25rem 0;
  }

  .register-buttons-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 626px) {
  .choices {
    width: 22rem;
  }
  .h1-title {
    font-size: 2rem;
    text-align: center;
    margin-top: 2rem;
  }

  .h1-title-2 {
    font-size: 1.9rem;
    text-align: center;
  }

  .p-title {
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #242523;
  }
  .register-stack {
    width: 100%;
    margin-top:.5rem;

  }

  .register-buttons-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
}



@media screen and (device-aspect-ratio: 40/71) {
  .container-choices {
    margin: 0;
    padding: 0;
  }

  .h1-title {
    font-size: 2rem;
    text-align: center;
    margin-top: 2rem;
  }

  .h1-title-2 {
    font-size: 1.9rem;
    text-align: center;
  }

  .p-title {
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #242523;
  }


}
