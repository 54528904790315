.assessment-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  min-height: calc(100vh - 12.1rem);
  min-width: 100%;
}

.questions-list {
  text-align: center;
  margin-top: 3rem;
}

.questions-list .label {
  font-size: 120%;
  margin-bottom: 5px;
}

.question-link {
  display: inline-block;
  font-size: 140%;
  padding: 3px 3px;
  width: 28px;
  margin: 3px 6px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  transition: transform .1s, opacity .1s;
  cursor: pointer;
  border-radius: 0.2rem;
  opacity: 0.7;
  background-color: #FF6200;
  color: #fff;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.question-link:hover {
  transform: scale(1.2) !important;
  opacity: 1;
}

.question-link.answered {
  font-weight: bold;
  opacity: 0.5;
  background-color: #999 !important;
  color: #fff;
}

.question-link.active {
  font-weight: bold;
  background: #FF6200;

  transform: scale(1.4);
  opacity: 1;
}

.question-link.active:hover {
  transform: scale(1.5) !important;
}

.toastify {
  font-size: 2rem;
  font-weight: bold;
}
