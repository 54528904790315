.alternative-container {
  display: flex;
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.25);
  padding: 0.4rem;
}

.alternative-container input {
  margin: 1rem;
  padding-top: 0.6rem;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 1px;
  cursor: pointer;
}

.answers {
  font-size: 1.5rem;
  color: #000;
  text-align: left;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.alternative-container:not(.nohover):hover {
  background-color: #DBDBDB;
  cursor: pointer;
}

.alternative-container.selected {
  background: #DBDBDB;;
  border: 1px solid #505050;
  border-radius: 1px;
  cursor: pointer;
}

.alternative-container.correct {
  background: #C9F8A6;
  border: 1px solid #63DC3E;
}

.alternative-container.wrong {
  background: #FFA2AC;
  border: 1px solid #FC3333;
}

.alternative-container.disabled {
  background: #C2C2C2;
  border: 0.407975px solid #C2C2C2;
  border-radius: 1px;
  cursor: pointer;
}

.alternative-container.selected .checkBox {
  background-image: url("../icons/checked.svg");
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
}

.alternative-container.selected.correct .checkBox {
  background-image: url("../icons/question-correct.svg");
  background-size: contain;
}

.alternative-container.selected.wrong .checkBox {
  background-image: url("../icons/question-wrong.svg");
  background-size: contain;
}

.checkBox {
  background-repeat: no-repeat;
  width: 2.8rem;
  height: 2.8rem;
  border: 0.1rem solid #fff;
  box-sizing: border-box;
  border-radius: 0.1rem;
  cursor: pointer;
}
