.instructions-container {
  display: flex;
  flex-flow: column wrap;
  max-width: 90%;
}

.instructions-title {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3.1rem;
  text-align: center;
  color: #242523;
  margin: 3rem 0 2.1rem 0;
}

.instructions-list {
  font-size: 1.5rem;
  margin: 0 1rem 5rem 3.1rem;
}

.instructions-list li {
  margin-bottom: 1.5rem;
}

.btns-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.stack-link {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: normal;
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.stack-link:hover {
  font-weight: 800;
}

/**************************** Media Querys ****************************/

@media only screen and (max-width: 720px) {
  .instructions-container {
    width: 35rem;
  }

  .btns-container {
    width: 34rem;
  }
}

@media screen and (device-aspect-ratio: 40/71) {
  .instructions-container {
    width: 31.8rem;
  }

  .btns-container {
    width: 31.8rem;
  }

  .instructions-list {
    font-size: 1.5rem;
    padding: 0 5rem;
    margin-bottom: 5rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .instructions-container {
    padding: 0;
    width: 74rem;
  }
}
