.navbar {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  background: #FF6200;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.navbar .navbar-logo {
  padding-left: 3rem;
  flex-grow: 10;
  cursor: pointer;
}

.navbar .navbar-icon {
  padding-top: 1.6rem;
  flex-grow: 1;
}

.navbar .desktop-menu {
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #FFF;
  margin: 1.6rem;
  cursor: pointer;
}

.navbar .mobile-menu {
  display: none;
}

.navbar .menu-style {
  display: flex;
  justify-content: flex-end;
  padding: 0.7rem 2.5rem 0.8rem;
  text-decoration: none;
  line-height: 2rem;
  width: 100%;
  color: #FFF;
}

@media only screen and (max-width: 720px) {
  .navbar .desktop-menu {
    display: none;
  }

  .navbar .menu {
    position: fixed;
    right: 0;
    width: 100%;
    height: 5.7rem;
    margin: -8rem 0 0 0;
    padding: 11rem 0 0 0;
    background: #FF6200;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
  }

  .navbar .menu-item {
    display: flex;
    font-size: 2.2rem;
    font-weight: 600;
    background-color: #FF6200;
  }

  .navbar .menu-item a {
    display: flex;
    justify-content: flex-end;
    padding: 0.7rem 2.5rem 0.8rem;
    text-decoration: none;
    line-height: 2rem;
    width: 100%;
    color: #FFF;
  }

  .navbar .menu-item a:hover {
    color: #242523;
    text-decoration: none;
    background: #FFF;
  }

  .navbar .active-menu-item {
    color: #FFF !important;
    background: #FF6200;
  }

  .navbar .mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 1.7rem;
    right: 1.5rem;
    z-index: 2;
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-select: none;
  }

  .navbar .mobile-menu input {
    display: block;
    width: 4rem;
    height: 3.2rem;
    position: absolute;
    top: -0.7rem;
    left: -0.5rem;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
  }

  .navbar .mobile-menu span {
    display: block;
    width: 3.3rem;
    height: 0.4rem;
    margin-bottom: 0.5rem;
    position: relative;
    background: #FFF;
    z-index: 1;
    transform-origin: 0.4rem 0rem;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  .navbar .mobile-menu span:first-child {
    transform-origin: 0% 0%;
  }

  .navbar .mobile-menu span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  .navbar .mobile-menu #mobile-menu-icon.checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-0.2rem, -0.1rem);
    background: #FFF;
  }

  .navbar .mobile-menu #mobile-menu-icon.checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  .navbar .mobile-menu #mobile-menu-icon.checked ~ span:nth-last-child(2) {
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
  }

  .navbar .mobile-menu #mobile-menu-icon.checked ~ ul {
    opacity: 1;
    transform: scale(1, 1);
  }

  .navbar .mobile-menu-overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    cursor: pointer;
  }

  .navbar .mobile-menu-overlay.visible {
    display: block;
  }

  .navbar .stop-scrolling {
    height: 100%;
    overflow: hidden;
  }
}
