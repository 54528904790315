.edit-profile-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  min-height: calc(100vh - 12.1rem);
  min-width: 100%;
  background: #fff;
}

.edit-profile-container .edit-profile-header {
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;
  width: 100%;
  height: 5rem;
  background: rgb(197,106,32);
  background: -moz-linear-gradient(270deg, rgba(197,106,32,1) 0%, rgba(215,200,188,1) 100%);
  background: -webkit-linear-gradient(270deg, rgba(197,106,32,1) 0%, rgba(215,200,188,1) 100%);
  background: linear-gradient(270deg, rgba(197,106,32,1) 0%, rgba(215,200,188,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c56a20",endColorstr="#d7c8bc",GradientType=1);
}

.edit-profile-container .edit-profile-header h1 {
  font-weight: bold;
  font-size: 2.4rem;
  margin-left: 1rem;
}

.edit-profile-container .edit-profile-buttons {
  display: flex;
  justify-content: space-between;
}
