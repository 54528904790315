.my-tests-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.my-tests-h3 {
  font-size: 2rem;
  cursor: pointer;
  padding: 1rem;
  color: #fff;
  text-align: center;

}

.my-tests-h3:hover img {
  transform: scale(1.2);
}

.my-tests-arrow {
  text-align: center;
  cursor: pointer;
  margin: 10px;
  transition: all 0.2s;
}

@keyframes jelly {
  0%,
  100% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
