.App {
  display: flex;
  flex-grow: 1;
}

.app-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
}

.content-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 12.1rem);
  min-width: 100%;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Medium.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Akrobat';
  src: url('./fonts/Akrobat.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
}
